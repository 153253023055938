.titlebox{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}
.title{
    font-size: 30px;
}
.subtitle{
    opacity: .8;
    text-align: center;
}
.titleline{
    display: flex;
    align-items: center;
    justify-content: center;
}
.titleline span{
    width: 50px;
    height: 3px;
    background: rgb(220, 220, 220);
}
.titleline span:nth-child(2){
    background: var(--fcolor);
}