.message{
    position:fixed;
    right: 20px;
    bottom: 20px;
    z-index: 100;
    background:var(--fcolor);
    height: 50px;
    width: 50px;
    border-radius: 50%;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.navlink{
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
}
.message:hover{
    height: 52px;
    width: 52px;
}