.cont{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    margin-bottom: 40px;
}
.box{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.lists{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 80vh;
    margin-top: 30px;
    position: relative;
}
.contlist{
    width: 100%;
    height: 100%;
    background-color:  var(--fcoloraa);
    backdrop-filter: blur(10px);
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}
.contlist2{
    width: 100%;
    height: 100%;
    background-color:  var(--fcoloraa);
    backdrop-filter: blur(10px);
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
}
.imgbox{
    width: 40%;
    min-height: 100%;
    overflow: hidden;
    object-fit: cover;
}
.textbox{
    width: 60%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.detailtitle{
    font-size: 30px;
    width: 90%;
    color: var(--fcolor);
    font-weight: bold;
}
.detaildes{
    width: 90%;
    color: black;
    font-size: 13px;
    overflow: auto;
    height: 300px;
}
.awardscont{
    width: 100%;
    min-height: 200px;
    background: var(--fcoloraa);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    flex-wrap: wrap;
    gap: 50px;
}
.awardbox{
    width: 150px;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.blackbox{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.viewawardbox{
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: #000000c4;
    display: flex;
    align-items: center;
    z-index: 30;
    justify-content: center;
}
.viewblackbox{
    width: 90%;
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.viewawardbtn{
    position: absolute;
    width: 50px;
    height: 50px;
    background: white;
    color: var(--fcolor);
    border-radius: 50%;
    display: none;
    cursor: pointer;
    align-items: center;
    justify-content: center;
}
.closeaward{
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0;
    color: white;
    padding: 10px;
    font-size: 30px;
}
.blackbox:hover .viewawardbtn{
    display: flex;
}
.awardimg{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.partners{
    width: 100%;
    min-height: 200px;
    flex-wrap: wrap;
    background: var(--fcoloraa);
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
}
.partnersimg{
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: contain;
}
.vidbox{
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}
.video{
    width: 48%;
    height: 100%;
}
.video1{
width: 48%;
height: 100%;
}
@media(max-width:700px){
    .lists{
        flex-direction: column;
        height:max-content;
        padding: 20px 0;
    }
    .imgbox{
        width: 90%;
        height:60%;
    }
    .textbox{
        width: 90%;
        height:max-content;
    }
    .contlist{
        width: 100%;
        height: 100%;
    }
    .contlist2{
        width: 100%;
        height: 100%;
    }
    .vidbox{
        display: flex;
        flex-direction: column;
    }

}