.cont{
    width: 100vw;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    z-index: 100;
    background: rgba(255, 255, 255, 1);
}
.gotop{
    position: fixed;
    right: 20px;
    bottom: 20px;
    background:var(--fcolor);
    padding: 10px;
    color: white;
    z-index: 10;
}
.navbox{
    height: 50px;
    width: 100%;
    background:var(--fcolora);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.linkbox{
    display: flex;
    align-items: center;
    gap: 5px;
    position: relative;
    width:90%;
}

.linkbox a{
    text-decoration: none;
    font-size: 16px;
}

.alinks{
    border:2px solid var(--fcolor);
    padding: 0;
    height: 35px;
    width: 150px;
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    justify-content: center;
    border-radius: 5px;
    transition: all .5s;
}
.alinks:hover{
    scale: 0.9;
}
.menulinkbox a{
    text-decoration: none;
    font-size: 16px;
    background: white;
    min-width: 100px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.menubox{
    display: none;
    color: white;
}
.closemenu{
    display: none;
}
.menulinkbox{
    display: none;
}
.callbtn{
    animation: bounce2 3s ease-out infinite;
}
@keyframes bounce2 {
      0%, 20%, 50%, 80%, 100% {transform: translateX(0);}
      40% {transform: translateX(15px);}
      60% {transform: translateX(1px);}
    }
@media(max-width:1050px){
    .menulinkbox{
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 10px;
        justify-content: center;
        width: 100%;
        height: 100vh;
        background: rgba(255, 255, 255, 0.005);
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(18.1px);
        -webkit-backdrop-filter: blur(18.1px);
        border: 1px solid rgba(255, 255, 255, 0.09);
        position: fixed;
        right: 0;
        z-index: 1000;
        transition: all .7s;
    }
    .menubox{
        display: flex;
    }
    .linkbox{
        display: none;
    }
    .closemenu{
        display: flex;
        position: absolute;
        top: 30px;
        right: 30px;
    }
}