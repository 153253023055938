.cont{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    margin-bottom: 40px;
}
.box{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}