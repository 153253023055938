.cont{
    width: 100%;
    gap: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    overflow: hidden;
}
.contpartner{
    background: white;
}
.listpartner{
    background: white;
}
.list{
    width: 100%;
    height: 250px;
    backdrop-filter: blur(10px);
    background: #000000b9;
    overflow: visible;
    margin: 30px 0;
}
.myswiper{
    width: 100%;
    overflow: visible;
    height: 100%;
    position: relative;
}
.slide{
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: visible;
    height: 100%;
}
.itembox{
    width: 300px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    transition: all .5s;
    object-fit: contain;
}
.itembox:hover{
    background: var(--fcolora);
    height: 250px;
}
.activeitembox{
    background: var(--fcolora);
    height: 250px;
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    transition: all .6s;
    object-fit: contain;
}
.itemimg{
    width: 130px;
    height: 130px;
    object-fit: contain;
    scale: 1.3;
}
.itemtxt{
    width: 90%;
    height: 50px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.itemtitle{
    font-weight: bold;
    font-size: 20px;
}
.itemdes{
    opacity: .9;
}
.customNavigation{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.navbtnl{
    font-size: 40px;
    color: var(--fcolor);
    font-weight: 100;
    position: absolute;
    z-index: 9;
    cursor: pointer;
}
.navbtnl:hover{
    color: white;
}
.navbtnr{
    font-size: 40px;
    color: var(--fcolor);
    font-weight: 100;
    position: absolute;
    right: 0;
    z-index: 9;
    cursor: pointer;
}
.navbtnr:hover{
    color: white;
}
.button {
    text-decoration: none;
    color: white;
    font-weight: bold;
}
