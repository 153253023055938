.cont{
    margin-top: 10px;
    gap: 10px;
    width: 100%;
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.title{
    background:var(--fcolor);
    color: white;
    font-size: 20px;
    height: 30%;
    width: 90%;
    overflow: auto; /* Add scrollbars for overflow content if needed */
    word-wrap: break-word; 
    display: flex;
    padding-left: 5px;
    padding-right: 5px;
    align-items: center;
    justify-content: center;
}
.description {
    width: 90%;
    height: 80%;
    overflow: auto; /* Add scrollbars for overflow content if needed */
    word-wrap: break-word; /* Ensure long words break onto the next line */
}
@media(max-width:700px){
    .title{
        width: 100%;
        font-size: 15px;
    }
    .description{
        width: 100%;
    }
}