.cont{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    margin-bottom: 40px;
}
.box{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.awardimg{
    width: 100%;
    margin-top: 20px;
    display: flex;
    gap: 10px;
}
.awardimg img{
    width: 50%;
}
.awarddes{
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;
    padding: 10px;
    font-size: 20px;
    color: white;
    width: 50%;
    height: auto;
    background:var(--fcolor);
}
@media(max-width:700px){
    .awardimg{
        width: 100%;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    .awardimg{
        width: 100%;
        margin-top: 20px;
        display: flex;
        gap: 10px;
    }
    .awardimg img{
        width: 100%;
    }
    .awarddes{
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: auto;
        padding: 10px;
        font-weight: bold;
        font-size: 20px;
        color: white;
        width: 100%;
        height: auto;
        background: #13099C;
    }
}